import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import {  } from '../../Store/actions';
import axios from '../../axios';
import styles from './Response.module.css';

class Response extends Component {
    state = {
        shop : {},
        user : {},
        address : {}
    }

    componentDidMount() {
        //get shop info
        axios.get(`/shop/${sessionStorage.getItem("shopId")}`)
        .then(response => {
            this.setState({shop : response.data});
        })
        .catch(error => {
            alert(error);
        });

        //get user
        axios.get(`/user/${sessionStorage.getItem('userId')}`)
        .then(response => {
            this.setState({user : response.data});
        })
        .catch(error => {
            alert(error);
        });

        axios.get(`/user/address/${sessionStorage.getItem('userId')}`)
        .then(response => {
            this.setState({address : response.data});
        })
        .catch(error => {
            alert(error);
        });

    }
    render() {
        //shop logo
        let img_alt = 'Logo';
        let img_src= './img/logo_grey.jpg';
        if (this.state.shop.logo) { 
            img_alt = this.state.shop.shop_name;
            // img_src = `https://api.findfix.gr:4000/${this.state.shop.logo}`;
            img_src = `https://boiling-stream-41133.herokuapp.com/${this.state.shop.logo}`;
        }; 

    return (
        <div className="container">
            <div className={styles.response}>
                <div className={styles.inner}>
                <h2>Το αίτημα σας για επισκευή στο {this.state.shop.shop_name} από το FindFix <br /><strong>έγινε δεκτό</strong></h2>
                <div>
                    <img src={img_src} alt={img_alt} width="100px"/>
                </div>
                <div>
                    <h4>{this.state.shop.shop_name}</h4>
                    <b>Περιοχή : {this.state.shop.locality}</b>
                    <p>Διέυθυνση : {this.state.shop.route} {this.state.shop.street_number}</p>
                    <p>Σε περίπτωση που θες να κάνεις κάποια αλλαγή κάλεσε απευθείας το κατάστημα:</p>
                    <b>Τηλέφωνο Καταστήματος : {this.state.shop.phone_number}</b>

                </div>
                <hr />
                <div>
                    <h4>Πελάτης</h4>
                    <p>{this.state.user.first_name} {this.state.user.last_name}</p>
                    <p>{this.state.address.route} {this.state.address.street_number} {this.state.address.locality} </p>
                    <b>Τηλέφωνο επικοινωνίας : {this.state.user.phone} </b>
                </div>
                <hr />
                <p>Μπορείς να κάνεις σύνδεση με τον προσωρινό κωδικό {sessionStorage.getItem('password')}</p>
                <div style={{marginTop : "100px"}}><Link to="/">Επιστροφή στην αρχική</Link></div>
                </div>
            </div>
        </div>
    )
    }
}


const mapStateToProps = state => {
    return {
        shpId : state.uiReducer.shopId,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Response);
